<div
  class="tw-block tw-h-full tw-overflow-hidden tw-rounded tw-border tw-border-solid tw-border-secondary-600 tw-relative tw-transition-all hover:tw-scale-105 focus-within:tw-outline-none focus-within:tw-ring focus-within:tw-ring-primary-700 focus-within:tw-ring-offset-2"
>
  <div class="tw-flex tw-bg-secondary-100 tw-items-center tw-justify-end tw-pt-4 tw-pr-4">
    <i class="bwi bwi-external-link"></i>
  </div>
  <div
    class="tw-flex tw-h-32 tw-bg-secondary-100 tw-items-center tw-justify-center tw-pb-2 tw-px-6 lg:tw-pb-4 lg:tw-px-12"
  >
    <div class="tw-flex tw-items-center tw-justify-center tw-h-28 tw-w-28 lg:tw-w-40">
      <img
        #imageEle
        [src]="image"
        alt=""
        class="tw-block tw-mx-auto tw-h-auto tw-max-w-full tw-max-h-full"
      />
    </div>
  </div>
  <div class="tw-p-5">
    <h3 class="tw-text-main tw-text-lg tw-font-semibold">{{ name }}</h3>
    <a
      class="tw-block tw-mb-0 tw-font-bold hover:tw-no-underline focus:tw-outline-none after:tw-content-[''] after:tw-block after:tw-absolute after:tw-w-full after:tw-h-full after:tw-left-0 after:tw-top-0"
      [href]="linkURL"
      rel="noopener noreferrer"
      target="_blank"
    >
    </a>
    <span *ngIf="showNewBadge()" bitBadge class="tw-mt-3" variant="secondary">
      {{ "new" | i18n }}
    </span>
  </div>
</div>
