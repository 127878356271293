<app-header>
  <bit-search
    class="tw-grow"
    [formControl]="searchControl"
    [placeholder]="'search' | i18n"
  ></bit-search>
  <a bitButton routerLink="create" *ngIf="manageOrganizations" buttonType="primary">
    <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
    {{ "newClient" | i18n }}
  </a>
  <button
    type="button"
    bitButton
    (click)="addExistingOrganization()"
    *ngIf="manageOrganizations && showAddExisting"
  >
    <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
    {{ "addExistingOrganization" | i18n }}
  </button>
</app-header>

<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="tw-sr-only">{{ "loading" | i18n }}</span>
</ng-container>

<ng-container *ngIf="!loading">
  <p *ngIf="dataSource.data.length < 1">{{ "noClientsInList" | i18n }}</p>
  <ng-container *ngIf="dataSource.data.length >= 1">
    <bit-table-scroll
      [dataSource]="dataSource"
      [rowSize]="53"
      class="tw-table tw-w-full table-hover table-list"
    >
      <ng-container header>
        <th bitCell colspan="2" bitSortable="organizationName">{{ "name" | i18n }}</th>
        <th bitCell bitSortable="seats">{{ "numberOfUsers" | i18n }}</th>
        <th bitCell bitSortable="plan">{{ "billingPlan" | i18n }}</th>
        <th bitCell></th>
      </ng-container>
      <ng-template bitRowDef let-row>
        <td bitCell width="30">
          <bit-avatar [text]="row.organizationName" [id]="row.id" size="small"></bit-avatar>
        </td>
        <td bitCell width="500">
          <a [routerLink]="['/organizations', row.organizationId]">{{ row.organizationName }}</a>
        </td>
        <td bitCell>
          <span>{{ row.userCount }}</span>
          <span *ngIf="row.seats != null"> / {{ row.seats }}</span>
        </td>
        <td bitCell>
          <span>{{ row.plan }}</span>
        </td>
        <td class="table-list-options" *ngIf="manageOrganizations">
          <div class="dropdown" appListDropdown>
            <button
              class="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              appA11yTitle="{{ 'options' | i18n }}"
            >
              <i class="bwi bwi-cog bwi-lg" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item text-danger" href="#" appStopClick (click)="remove(row)">
                <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
                {{ "remove" | i18n }}
              </a>
            </div>
          </div>
        </td>
      </ng-template>
    </bit-table-scroll>
  </ng-container>
</ng-container>
